import React, { useState, useContext,  useEffect } from "react"
import Layout from "../../layouts"
import { GlobalStateContext } from "../../context/GlobalContextProvider"
import { CfpSearch } from "../../components/cfp/cfpSearch"
import CheckTable from "../../components/attendance/checkTable"
import { styled } from "@mui/material/styles"
import { Link } from "gatsby"
import {
  Card,
  CardHeader,
  Box,
  Typography,
  Button,
  CardContent,
  Collapse,
  IconButton
} from "@mui/material"
import QrCodeIcon from "@mui/icons-material/QrCode"
import SmartphoneIcon from "@mui/icons-material/Smartphone"
import InfoIcon from "@mui/icons-material/Info"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import Cfptb from "../../components/cfp/cfptb"
import { cfpColumns } from "../../components/utils/cfpcolumns"
import {cfputils, useDialog} from "../../components/utils/cfputils"
import { gUtils } from "../../components/utils/gutils"

export default function CfpIndex({ location }) {
  const [openAdd, setOpenAdd] = useState(false)
  const [tbData, settbData] = useState([])
  const [isBlurred, setIsBlurred] = useState(false) // 添加頁面過濾狀態
  const {
    gCfp,
    gStripe,
    setCustomModalOpen,
    gIotCfp,
    setModalContent,
    ts,
  } = useContext(GlobalStateContext)

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  })
  const setIsOpenDialog = useDialog()
    useEffect(()=>{

      return ()=>{
        setIsOpenDialog(false)
      }
    },[])
  useEffect(() => {

    console.log("当前设备",gCfp.cfpDevices,gCfp.allDevices)


    settbData(gCfp.cfpDevices)
  }, [gCfp.cfpDevices])

  useEffect(() => {
    // 確認gStripe物件和gStripe.levelInfo存在、再添加頁面過濾狀態
    if (
      gStripe &&
      gStripe.levelInfo &&
      (gStripe.levelInfo.level === 0 && !gStripe.customer.isRootUser)
    ) {
      setIsBlurred(false)
    } else {
      setIsBlurred(false)
    }
  }, [gStripe])
  useEffect(() => {
    gIotCfp.subIotDevices()
    return ()=>{
        gIotCfp.cancelSubscriptions();
    }
  }, []);

  const handleClose = () => {
    setCustomModalOpen(false)
  }

  const handleOpenModal = () => {
    setModalContent(
      <CheckTable
        title={"認証機器を選択"}
        setOpenModal={setCustomModalOpen}
        selectableRows={"multiple"}
        handleClose={handleClose}
        data={gCfp.cfpChooseDevices}
        handleCheck={handleCheck}
      />
    )
    setCustomModalOpen(true)
  }

  const handleCheck = (is) => {
    gCfp.addCfpDevices(is)
    setCustomModalOpen(false)
  }

  return (
    <Layout location={location}>
      {/* <AccessCheck access="ロール管理"> */}
      <Box sx={{ display: "flex", flexDirection: "column", position: "relative" }}>
      {isBlurred && (
          <React.Fragment>
            <Box sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.5)", // 半透明の背景色を追加
              zIndex: 10,
            }} />
            <Box
              sx={{
                width: '500px',
                height: '150px',
                p: 4,
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 20,
                // background: '#f5f5f7',
                textAlign: 'center',
              }}
            >
              認証機器のページを閲覧するには、設定ページにて<br />Lightプラン以上へのアップグレードが必要です。<br />（Lightプラン以上では今後Hub3との併用で利用可能）
              <Typography
                component={Link}
                to="/settings"
                color="#000000"
                sx={{ textDecoration: 'underline', fontWeight: 'bold', paddingTop: "10px" }}
              >
                設定ページはこちら
              </Typography>
            </Box>
          </React.Fragment>
        )}
        <Box sx={{ filter: isBlurred ? 'blur(5px)' : 'none', pointerEvents: isBlurred ? 'none' : 'auto' }}>
          <CfpSearch
          callSearch={(e) => {
            if (e) {
              settbData(gUtils.findSearchList(e, gCfp.cfpDevices))
            } else {
              settbData(gCfp.cfpDevices)
            }
          }}
        />
        <Collapse in={openAdd} timeout="auto" unmountOnExit sx={{ mt: 2 }}>
          <Card sx={{ mb: "15px" }}>
            <CardHeader
              title={
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h2" sx={{ ml: "9px" }}>
                    新規デバイス(認証機器)を追加
                  </Typography>
                  <IconButton
                    onClick={() => {
                      setOpenAdd(false)
                    }}
                    sx={{ '&:hover': { color: 'primary.main' } }}
                  >
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </Box>
                }
              />
            <CardContent>
              <Box sx={{ display: "flex" }}>
                <Typography>新規デバイスの追加方法を選択</Typography>
                <CustomWidthTooltip
                  title={
                    <div>
                      【QRコード】QRコードからデバイスをインポート
                      <br />
                      【個人で登録済のデバイス】個人でセサミアプリに登録済みのデバイスをインポート
                    </div>
                  }
                  placement="right-start"
                >
                  <InfoIcon
                    sx={{ color: "#cccccc", cursor: "pointer", ml: "5px" }}
                  />
                </CustomWidthTooltip>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Button
                  varient="text"
                  component="label"
                  startIcon={<QrCodeIcon />}
                >
                  QRコード
                  <input
                    type="file"
                    hidden
                    onChange={(e) => {
                      const fileInput = e.target;
                      cfputils.readQrcode(e.target.files[0], (e, d) => {
                        if (d) {
                          if (cfputils.isTouch(d.deviceModel)) {
                            if (cfputils.hasListObj(gCfp.cfpDevices, [d], "deviceUUID")) {
                              ts({ open: true, msg: "接続済みです" })
                            } else {
                              gCfp.addCfpDevices([d])
                            }
                          } else {
                            ts({ open: true, msg: "このモデルはサポートされていません。" })
                          }
                        } else {
                          ts({
                            open: true,
                            msg: "読み取りに失敗しました。QRコードが正しいか確認してください。",
                          })
                        }
                        fileInput.value = '';
                      })
                    }}
                  />
                </Button>
                <Button
                  variant="text"
                  startIcon={<SmartphoneIcon />}
                  onClick={() => {
                    handleOpenModal(true)
                  }}
                >
                  個人で登録済のデバイス
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Collapse>
        <Cfptb
          callAdd={() => {
            setOpenAdd(true)
          }}
          isAdd={!openAdd}
          data={tbData}
          path={"/cfp/region"}
          isBind={false}
          isBack={false}
          text={"認証機器一覧"}
          columns={cfpColumns.touchColumns({
            datas: tbData,
            listNames: gCfp.cfpSsmDevices,
          })}
          callDelData={(items) => {
            gCfp.removeCfpDevices(items)
          }}
        />
        </Box>
      </Box>
    </Layout>
  )
}
